/**
 * Define an indexable context object to store a map of 
 */
export default class Context {
  vars: Map<string, any>
  constructor() {
    this.vars = new Map();
  }

  put(key: string, value: any): void {
    this.vars.set(key, value);
  }

  get(key: string): any {
    return this.vars.get(key);
  }

  remove(key: string): void {
    this.vars.delete(key);
  }
}