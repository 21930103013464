const VAR_PATTERN = /\${([^}]*)}/g

export default class Interpolator {
  static interpolate(message: string, context: Map<String, any>): string {
    // find any variable in the message
    const matches: RegExpMatchArray | null = message.match(VAR_PATTERN);

    // if there are no matches, there is nothing to interpolate. Return
    if (!matches) {
      return message;
    }

    // resolve any match found
    let tempMessage = message;

    matches.forEach((match) => {
      // find the matching var value
      const value: any = context.get(match.substring(2, match.length - 1));

      if (value === null || value === undefined) {
        throw new Error(`variable ${match} not found in context`);
      }
      tempMessage = tempMessage.replace(match, value);
    });
    // return the interpolated message
    return tempMessage;
  }
}