









































































































import Vue from 'vue';
import Websocket from '../data/websocket';
import Messages from '../components/Messages.vue';
import Code from '../components/Code.vue';
import Context from '../data/context';
import Interpolator from '../data/interpolator';
import Split from "split.js";
import Message from '../data/message';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  name: 'Socket',
  components: {
    Messages,
    Code
  },
  metaInfo(): MetaInfo {
    return {
      title: 'Socket',
      meta: [
        { name: 'Socket', content: 'Connect to your websocket endpoint and start sending/receiving messages to test your api' },
        { property: 'og:title', content: 'Coderpunk tools Socket' },
        { property: 'og:site_name', content: 'Coderpunk tools' },
        { property: 'og:description', content: 'Connect to your websocket endpoint and start sending/receiving messages to test your api' },
        { property: 'og:type', content: 'webapp'},
        { property: 'og:url', content: 'https://tools.coderpunk.tech/#/socket' },
        { property: 'og:image', content: '@/assets/Coderpunk.png' }    
      ]
    }
  },
  data: () => ({
    connected: false,
    connecting: false,
    somethingErrored: false,
    errorMessage: '',
    websocket: new Websocket(),
    url: '',
    context: new Context(),
    sendContent: '',
    receivedContent: '',
    selectedMessage: new Message(Message.Direction.OUT, 'ping')
  }),
  mounted() {
		Split(['#send', '#receive'], {
				sizes: [50, 50],
				gutterSize: 2
		});
		Split(['#left', '#right'], {
				sizes: [25, 75],
				gutterSize: 2
		});
  },
  computed: {
    icon(): string {
      return this.connected ? "mdi-link" : "mdi-link-off";
    },
    connectionColor(): string {
      return this.connected ? this.$store.state.color : "default";
    },
    hint(): string {
      return this.connected ? "Connected" : "Disconnected";
    },
    messages(): Array<Message> {
      return this.websocket.messages;
    }
  },
  methods: {
    connect(): void {
      // reset the errors
      this.errorMessage = '';
      this.somethingErrored = false;
      // check if we can connect
      if (!this.websocket.url) {
        this.errorMessage = 'Cannot connect: url is missing';
        this.somethingErrored = true;
        return;
      }

      // go ahead and connect
      let waiting: ReturnType<typeof setTimeout>;
      this.connecting = true;
      this.websocket.connect()
        .then(() => {
          this.connected = true;
          this.connecting = false;
          clearTimeout(waiting);
        }, (err) => {
          this.connecting = false;
          this.connected = false;
          this.errorMessage = `Cannot connect: ${err.message}`;
          this.somethingErrored = true;
        });
      const _this = this;
      waiting = setTimeout(() => {
        if (_this.connecting) {
          _this.connecting = false;
          _this.connected = false;
          _this.errorMessage = 'Cannot connect: could not reach the server after 10 seconds';
          _this.somethingErrored = true;
        }
      }, 10000);
    },
    disconnect(): void {
      this.websocket.disconnect();
      this.connected = false;
      this.connecting = false;
    },
    toggleConnection(): void {
      if (this.connected) {
        this.disconnect();
      } else {
        this.connect();
      }
    },
    setUrl(): void {
      this.websocket.setUrl(this.url);
    },
    deleteMessage(index: number): void {
      this.websocket.messages.splice(index, 1);
    },
    selectMessage(message: Message): void {
      this.selectedMessage = message;
      this.receivedContent = this.prettyfyData(message.data)
    },
    updateSendContent(value: string): void {
      this.sendContent = value;
    },
    updateReceivedContent(value: string): void {
      this.receivedContent = this.prettyfyData(value);
    },
    prettyfyData(data: string): string {
      // try prettyfying the string if it is json
      try {
        return JSON.stringify(JSON.parse(data), null, 2);
      } catch (error) {
        return data;
      }
    },
    send(): void {
      this.somethingErrored = false;
      this.errorMessage = '';
      try {
        this.websocket.sendMessage(Interpolator.interpolate(this.sendContent, this.context.vars))
      } catch (error) {
        this.somethingErrored = true;
        this.errorMessage = `Failed to send message: ${error.message}`;
      }
    },
    resend(): void {
      this.somethingErrored = false;
      this.errorMessage = '';
      try {
        this.websocket.sendMessage(Interpolator.interpolate(this.receivedContent, this.context.vars))
      } catch (error) {
        this.somethingErrored = true;
        this.errorMessage = `Failed to re-send message: ${error.message}`;
      }
    }
  },
  watch: {
    websocket: {
      handler(newVal) {
        if (!newVal.connection || newVal.connection.state > 1) {
          this.connected = false;
        }
      }, 
      deep: true
    },
    messages() {
      const last = (this.messages.length ? this.messages[0] : null)

      if (last && last.direction === Message.Direction.IN) {
        this.selectedMessage = last;
        this.receivedContent = this.prettyfyData(last.data);
			}
    }
  }
});
