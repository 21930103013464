<template>
    <div class="code-editor boxed">
        <div class="notheme">
            <codemirror :value="codeContent"
            :options="cmOptions"
            @input="onCmCodeChange"
            @ready="onCmReady"
            @viewportChange="viewportChange"
            ref="codeeditor"></codemirror>
        </div>
    </div>
</template>
<script lang="js">
import Vue from "vue";
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/lint/json-lint.js';
import 'codemirror/addon/lint/javascript-lint.js';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/xml-fold.js';
import 'codemirror/addon/fold/indent-fold.js';
import 'codemirror/theme/base16-dark.css';
import { JSHINT } from 'jshint'

export default Vue.extend({
  name: "Code",
  components: {
    codemirror
  },
  props: {
    content: String,
    mode: Object
  },
  data: () => ({
    cmOptions: {
      tabSize: 2,
      mode: {
        name: 'javascript',
        json: false
      },
      lineNumbers: true,
      line: true,
      collapseIdentical: true,
      styleActiveLine: true,
      matchBrackets: true,
      autoCloseBrackets: true,
      lint: {
        esversion: 6
      },
      foldGutter: true,
      gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
      theme: 'base16-dark'
    }
  }),
  mounted() {
    const editorTheme = this.$store.state.editorTheme;
    if (editorTheme !== 'default') {
      require('codemirror/theme/' + editorTheme + '.css');
    }
    this.cmOptions.theme = editorTheme
    this.cmOptions.mode = this.mode;
    if (this.mode === {
      name: 'javascript',
      json: false
    }) {
      window.JSHINT = JSHINT;
      this.cmOptions.lint = {
        esversion: 6
      };
    } else {
      this.cmOptions.lint = false;
    }

    // this.theme = editorTheme;
  },
  computed: {
    codeContent() {
      return this.content;
    }
  },
  methods: {
    onCmCodeChange(newVal) {
      this.$emit("code_changed", newVal);
    },
    onCmReady() {
      // do nothing for now
    },
    viewportChange: function() {
      // TODO verify if this is required
      // FIXME ugly hack to fix the gutter hiding the content in the environment variables editor
      const gutters = this.$el.querySelector(".CodeMirror-gutter.CodeMirror-linenumbers");
      gutters.style = "width: 0px !important;"
    }
  },
  watch: {
    theme(newVal) {
      this.cmOptions.theme = newVal;
    }
  }
})
</script>
<style lang="scss">
.CodeMirror {
  font-size: .91rem;
  height: 55vh;
  min-height: 450px;
//   overflow: auto;
}
.CodeMirror-hscrollbar {
    bottom: 0;
    left: 0; 
    overflow-y: auto;
    overflow-x: auto;
}
.boxed {
  // -webkit-box-shadow: 0 1px 8px 1px $default-border-color;
  border-radius: 2px;
  border: 1px solid rgb(41, 41, 41);
}
</style>