/**
 * Enum representing the different directions of a message 
 */
enum Direction {
  IN = 'RECEIVED',
  OUT = 'SENT'
}

/**
 * Represents a websocket message object
 */
export default class Message {
  /**
   * expose the {@link Direction} enum as a static field of this class
   */
  static Direction = Direction
  /**
   * the message direction
   */
  direction: Direction
  /**
   * the message body
   */
  data: string
  /**
   * the message timestamp in ISO string
   */
  timestamp: string
  constructor(direction: Direction, data: string) {
    this.direction = direction;
    this.data = data;
    this.timestamp = new Date().toISOString();
  }

  /**
   * Parse the data string into a json object.
   * 
   * @returns a json object representation of the message data
   * @throws {@link SyntaxError} when the data is not a stringified json object
   */
  dataAsJson(): any {
    return JSON.parse(this.data);
  }
}