<template>
  <div class="messages">
    <v-card
    >
      <v-toolbar>  
        <v-toolbar-title>Messages</v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="clearMessages">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-toolbar>
  
      <v-list>  
        <v-list-item
            v-on:click.stop="selectMessage(item)"
            v-for="(item, index) in items"
            :key="index"
        >
          <v-list-item-avatar>
            <v-icon
                :color="getIconClass(item)"
            >
              {{messageIcon(item)}}
            </v-icon>
          </v-list-item-avatar>
  
          <v-list-item-content>
            <v-list-item-title v-text="item.data"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.prevent="deleteMessage($event, index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import Message from "../data/message";

export default Vue.extend({
  name: "Messages",
  props: {
    websocket: Object
  },
  data: () => ({

  }),
  methods: {
    clearMessages: function() {
      this.websocket.clearMessages()
    },
    deleteMessage: function(ev, message) {
      ev.preventDefault()
      this.$emit("delete_message", message)
    },
    selectMessage: function (msg) {
      this.$emit("select_message", msg)
    },
    messageIcon: function(item) {
      return item.direction === Message.Direction.IN ? "mdi-call-received" : "mdi-call-made";
    },
    getIconClass: function(item) {
      return item.direction === Message.Direction.IN ? `${this.$store.state.color} lighten-4` : this.$store.state.color; 
    }
  },
  computed: {
    items: function() {
      return this.websocket.messages;
    }
  }
});
</script>
<style lang="scss" scoped>

</style>